<template>
  <div
    ref="chatTalkHeader"
  >
    <div
      v-if="talkId"
      class="w-100 d-inline-flex justify-content-between mb-1"
    >
      <user-profile-link
        v-if="talkCustomer"
        :user="talkCustomer"
      >
        <b-avatar
          :src="talkCustomer.avatar"
          size="sm"
        />
        <strong class="ml-1">{{ talkCustomer.display_name }}</strong> (User Id: {{ talkCustomer.user_id }})
      </user-profile-link>
      <font-awesome-icon :icon="['fas', 'xmark']" @click="onClose" />
    </div>
    <strong class="small mb-0 d-inline-flex align-items-center justify-content-between w-100">
      <talk-link :talk="talkData">
        {{ $t('eChat_title', { talkId }) }}
      </talk-link>
    </strong>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'ChatTalkHeader',
  props: {
    talkId: {
      type: [Number, String],
      default: null,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      getByName: 'repos/getByName',
      getChatOpen: 'chat/getChatOpen'
    }),
    ...mapFields({
      loadingEnable: 'loadingEnable'
    }),
    talkData () {
      if (this.talkId !== null && parseInt(this.talkId) > 0) {
        const chat = this.getChatOpen(this.talkId)
        if (chat && chat.talk) {
          return chat.talk
        }
      }
      return {}
    },
    talkCustomer () {
      return this.talkData?.user
    }
  },
  methods: {
    onClose () {
      this.$emit('on-close')
    }
  }
}
</script>

<style scoped>

</style>
